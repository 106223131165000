export function usePageTracking(trackingData?: TrackingData) {
  const { data: jobShop } = useNuxtData("jobShopData")

  /**
   * register tracking events after mounting the rendered page
   */
  onMounted(() => {
    trackPageViewAmpl(trackingData)

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden")
        trackScrollDepth()
    })

    window.addEventListener("beforeunload", () => {
      trackScrollDepth()
    })

    if (jobShop.value?.externalScriptSettings.isCookielessTrackingEnabled) {
      trackPageViewMatomo()
    }
    else {
      window.addEventListener("ccm19WidgetLoaded", () => {
        trackPageViewGTM()
      })
      window.addEventListener("ccm19WidgetClosed", () => {
        trackPageViewGTM()
      })
    }
  })
}
